import $ from 'jquery';
import 'owl.carousel';
import './jquery.selectric.min.js';
import validate from "jquery-validation";
import IMask from "imask";
import sbjs from 'sourcebuster';
import QrCreator from 'qr-creator';

$(document).ready(function () {
    const YA_CLID = 86541630;
    var spinnerData = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Отправка...';
    const themeInput = $('#top-panel-form-theme');
    const emailBox = $('.js-top-panel-form-emailcontainer');
    const addLabelText = $('.custom-flex-container__add-text');
    const emailInput = $('#top-panel-form-email');
    const emailInputFooter = $('.registration-box__form-input');
    const btn = $('.js-top-panel-form-btn');
    const btnFooter = $('.registration-box__form-btn');
    const firstForm = $('.top-panel__first-form');
    const secondFrom = $('.top-panel__second-form');
    var user_id;
    var $popup = $('.popoup');
    var $topslider = $('.js-carousel');

    function setCookie(name, value, options) {
        options = options || {};

        var expires = options.expires;

        if (typeof expires == "number" && expires) {
            var d = new Date();
            d.setTime(d.getTime() + expires * 1000);
            expires = options.expires = d;
        }
        if (expires && expires.toUTCString) {
            options.expires = expires.toUTCString();
        }

        value = encodeURIComponent(value);

        var updatedCookie = name + "=" + value;

        for (var propName in options) {
            updatedCookie += "; " + propName;
            var propValue = options[propName];
            if (propValue !== true) {
                updatedCookie += "=" + propValue;
            }
        }

        document.cookie = updatedCookie;
    }

    function sendGoalToYandex(counterId, goalId) {
    }

    function openPopup(id = false) {
        var popup = $(".popoup");
        if (id) {
            popup = $("#" + id);
        }
        popup.addClass('open');
    }

    function closePopup() {
        $popup.removeClass('open');
    }

    function validateEmail(email) {
        var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        return re.test(String(email).toLowerCase());
    }

    function deactivateBtn(btn) {
        btn.attr('disabled', true);
        btn.html(spinnerData);
    }

    function activateBtn(btn, btnText) {
        btn.attr('disabled', false);
        btn.text(btnText);
    }

    const createPhoneMask = (elem) => {
        const maskInput = IMask(elem, {
            mask: "+{7} (#00) 000-00-00",
            definitions: {
                "#": /[0-69]/,
            },
            lazy: true, // make placeholder always visible
        });

        elem.addEventListener("keypress", function () {
            maskInput.updateOptions({ lazy: false });
        });
    };
    //init sbjs
    sbjs.init();

    $topslider.owlCarousel({
        loop: false,
        margin: 0,
        autoplay: false,
        nav: true,
        dots: true,
        responsive: {
            0: {
                items: 1,
                slideBy: 1
            },
            768: {
                items: 2,
                slideBy: 2
            },
            1200: {
                items: 3,
                slideBy: 3
            }
        }
    });

    $(document).on('focus', 'input', function (e) {
        setCookie("showidget2", time(), { 'path': '/' });
        setCookie("showidget", time(), { 'path': '/' });
    })

    $('.validate-form').each(function () {
        $(this).validate({
            rules: {
                email: {
                    required: true,
                    email: true
                },
                theme: {
                    required: true
                },
            },
            messages: {
                email: {
                    required: 'Поле e-mail обязательно для заполнения',
                    email: 'Введите корректный e-mail'
                },
                theme: {
                    required: 'Поле "Тема работы" обязательно для заполнения'
                },
            },
            submitHandler: function (form) {
                // some other code
                // maybe disabling submit button
                // then:
                var submitBtn = $(form).find('button[type="submit"]');
                var submitBtnText = submitBtn.text();
                deactivateBtn(submitBtn);
                $.ajax({
                    type: 'POST',
                    url: '/wp-json/crm/v1/createUser/',
                    data: $(form).serialize(),
                    success: function (res) {
                        var url;
                        ym(YA_CLID, 'reachGoal', 'ORDER');
                        if (res.authlink) {
                            url = new URL(res.authlink + '/firstOrder/');
                        } else {
                            url = new URL('https://studservis-lk.ru/orders/edit/');
                        }
                        res.selected_work ?
                            url.searchParams.set('tpw', res.selected_work) : null;
                        res.theme ?
                            url.searchParams.set('theme', res.theme) : null;
                        window.location.href = url.toString();
                    },
                    error: function () {
                        activateBtn(submitBtn, submitBtnText);
                        alert('Ошибка отправки.');
                    }
                });

            }
        })
    });

    $("#calc_step").on('click', function () {
        $("#calc_email").show();
        $(this).hide();
        $("#calc_result").show();
        $("#calc_submit").show();

    });

    $('.js-popup-open').on('click', function () {
        var id = false;
        id = $(this).attr('data-popup');
        openPopup(id);
    });

    $('.js-popup-close').on('click', function () {
        closePopup();
    });

    $('#top-panel-form-files').on('change', function (e) {
        const target = $(e.target);
        var filesLength = target.prop('files').length;
        if (filesLength) {
            $(".custom-flex-container__files-count").text(filesLength + ' шт.');
        }
    });

    $('.js-top-slider-dot').on('click', function () {
        var index = $(this).index();

        $topslider.trigger('to.owl.carousel', [index, 300]);
    });

    btnFooter.on('click', function (e) {
        e.preventDefault();
        const valid = validateEmail($(emailInputFooter).val());
        const btnText = $(this).attr('text');
        if (!valid) {
            emailInputFooter.addClass('error');
            emailInputFooter.parent().find('.error-text').removeClass('hidden');
            return false;
        }
        if (valid) {
            deactivateBtn($(this));
            $.ajax({
                type: 'POST',
                url: '/wp-json/crm/v1/createUser/',
                data: $(this).parents('form:first').serialize(),
                success: function (res) {
                    var url;
                    ym(YA_CLID, 'reachGoal', 'ORDER')
                    /* selectedWork = (typeof res.selected_work !== "undefined" && res.selected_work) ? '?tpw=' + res.selected_work : '';
                    if (typeof res.authlink !== "undefined") {
                        window.location.href = res.authlink + '/firstOrder/' + selectedWork;
                    } else {
                        window.location.href = 'https://studservis-lk.ru/orders/edit/' + selectedWork;
                    } */
                    if (res.authlink) {
                        url = new URL(res.authlink + '/firstOrder/');
                    } else {
                        url = new URL('https://studservis-lk.ru/orders/edit/');
                    }
                    res.selected_work ?
                        url.searchParams.set('tpw', res.selected_work) : null;
                    res.theme ?
                        url.searchParams.set('theme', res.theme) : null;
                    QrCreator.render({
                        text: "https://t.me/testMattStudbot?start=" + $('#top-panel-form-email').val().replace('@', '__').replace('.', '_'),
                        radius: 0.5, // 0.0 to 0.5
                        ecLevel: 'H', // L, M, Q, H
                        fill: '#000000', // foreground color
                        background: null, // color or null for transparent
                        size: 250 // in pixels
                    }, document.querySelector('#qr-code'));
                    openPopup('qr-code-popup');
                    document.querySelector('#qr-code-popup .qr-code-popouop__info-lk-link').href = url.toString();
                    document.querySelector('#qr-code-popup .qr-code-popouop__info-tg-link').href = "https://t.me/testMattStudbot?start=" + $('#top-panel-form-email').val().replace('@', '__').replace('.', '_');
                    /* window.location.href = url.toString(); */
                },
                error: function () {
                    activateBtn($(this), btnText)
                    alert('Ошибка отправки.');
                }
            });
        }

    });

    let biggestFormCheck = document.querySelector('#biggest-form-first');

    if (biggestFormCheck) {
        if (document.querySelector("#top-panel-form-phone")) {
            createPhoneMask(document.querySelector("#top-panel-form-phone"));
        }
        const selectTypeOfWork = $('#top-panel-form-type_of_work');
        const inputCourse = $('#top-panel-form-course');
        const inputTheme = $('#top-panel-form-theme');
        const inputEmail = $('#top-panel-form-email');
        const coursesList = $('.custom-flex-container__courses-list');
        const phoneInput = document.querySelector("#top-panel-form-phone");
        const inputName = document.querySelector('#top-panel-form-name');
        var authLink;
        var listCourses;

        if (phoneInput) {
            createPhoneMask(phoneInput);
        }

        $(document).on('click', function (e) {
            const target = $(e.target);
            if (!target.closest('.custom-flex-container__courses-list-wrapper').length && listCourses) {
                coursesList.removeClass('custom-flex-container__courses-list--opened');
                var choosen = listCourses.filter((item) => item.name === inputCourse.val());
                if (!choosen.length) {
                    inputCourse.val('');
                }
            }
        })

        $.ajax({
            type: 'GET',
            url: '/wp-json/crm/v1/getWorkTypesList/',
            success: function (res) {
                if (res.list) {
                    res.list.map((item) => {
                        selectTypeOfWork.append($('<option>', {
                            value: item.name,
                            text: item.name
                        }));
                    }
                    );
                }
                selectTypeOfWork.selectric({
                    maxHeight: 200,
                    onChange: function (element) {
                        $(element).change();
                        if ($(element).hasClass('pop_calc_select')) {
                            $("#calc_sale > span, #calc_price > span").text('-');
                            var job = $(element).find('option:selected').attr('data-job');
                            $("#calc_step").attr('disabled', false);
                            console.log(job)
                            if (typeof job !== "undefined") {
                                $.ajax({
                                    type: 'POST',
                                    url: '/wp-json/crm/v1/getJobInfo/',
                                    data: {
                                        tag: job
                                    },
                                    success: function (res) {
                                        console.log(res);
                                        console.log(typeof res.fields);
                                        console.log((res.fields && typeof res.fields !== "undefined" && res.fields.tpw_price && typeof res.fields.tpw_price !== "undefined"));
                                        if (typeof res.fields !== "undefined" && res.fields.tpw_price) {
                                            var sale = (parseInt(res.fields.tpw_price) * 0.3);
                                            sale = Math.round(sale / 100) * 100;
                                            $("#calc_price > span").text(parseInt(res.fields.tpw_price));
                                            $("#calc_sale > span").text(parseInt(sale));
                                        }
                                    },
                                    error: function (xhr, status, error) {
                                        var err = JSON.parse(xhr.responseText);
                                        console.log(err);
                                    }
                                });

                            }

                        }
                    },
                });
            },
            error: function (error) {
                console.log(error)
            }
        });

        /*         $.ajax({
                    type: 'GET',
                    url: '/wp-json/crm/v1/getCoursesList/',
                    success: function (res) {
                        listCourses = res.list;
                    },
                    error: function (error) {
                        console.log(error);
                    }
                }); */

        coursesList.find('li').on('click', function (e) {
            const target = $(e.target);
            inputCourse.val(target.text());
        });

        inputCourse.on('input', function () {
            let value = inputCourse.val();
            if (value.length > 2) {
                $.ajax({
                    type: 'POST',
                    url: '/wp-json/crm/v1/getCoursesList/',
                    data: { query: value, sort: 'by_name' },
                    success: function success(response) {
                        coursesList.addClass('custom-flex-container__courses-list--opened');
                        let res = response;
                        if (res.error) {
                            coursesList.html('');
                            coursesList.removeClass('custom-flex-container__courses-list--opened');
                            return;
                        }
                        if (res.list && res.list.length) {
                            coursesList.html('');
                            let slidedArray = res.list.slice(0, 7);
                            slidedArray.map((item) => {
                                let searchString = value.toLowerCase();
                                let lengthString = searchString.length;
                                let index = item.name.toLowerCase().indexOf(searchString) !== -1 ? item.name.toLowerCase().indexOf(searchString) : 0;
                                let firstString = item.name.slice(0, index);
                                let middleString = item.name.slice(index, index + lengthString);
                                let lastString = item.name.slice(index + lengthString, undefined);
                                let resultName = `${firstString}<span class="custom-flex-container__courses-item-highlighted">${middleString}</span>${lastString}`;
                                coursesList.append(
                                    `<li class="custom-flex-container__courses-item" data-value="${item.id}">${resultName}</li>`
                                )
                            })
                        }
                        coursesList.find('li').on('click', function (e) {
                            const target = $(e.target);
                            inputCourse.val(target.text());
                            coursesList.removeClass('custom-flex-container__courses-list--opened');
                        })
                        console.log(res);
                    },
                    error: function error(xhr, status, _error) {
                        console.log(_error);
                        console.log(xhr);
                    }
                })
            } else {
                coursesList.removeClass('custom-flex-container__courses-list--opened');
            }

        })

        selectTypeOfWork.on('change', function () {
            if (selectTypeOfWork.closest('.selectric-wrapper').hasClass('error') && selectTypeOfWork.val() !== 0) {
                selectTypeOfWork.closest('.selectric-wrapper').removeClass('error')
            }
        })

        $('#biggest-form-first').on('submit', function (e) {
            e.preventDefault();
            const target = $(e.target);
            var inputCourseValidation = inputCourse.valid()
            var inputThemeValidation = inputTheme.valid()
            var inputEmailValidation = validateEmail(inputEmail.val());

            if (selectTypeOfWork.val() !== 0 && inputCourseValidation && inputThemeValidation && inputEmailValidation) {
                deactivateBtn($('.custom-flex-container__button'));
                const formArray = target.serializeArray();
                let filesArray = target.find("#top-panel-form-files");
                const formData = new FormData();

                for (let elem of formArray) {
                    formData.append(elem.name, elem.value);
                }

                if (!!filesArray.length) {
                    filesArray = filesArray[0].files;
                    Array.from(filesArray).forEach((file) => {
                        formData.append("files[]", file);
                    });
                }
                $.ajax({
                    type: 'POST',
                    url: '/wp-json/crm/v1/createOrder/',
                    data: formData,
                    dataType: "json",
                    processData: false,
                    contentType: false,
                    success: function (res) {
                        ym(YA_CLID, 'reachGoal', 'ORDER')

                        var indexEmail = formArray.findIndex((item) => item.name === 'email');
                        var email = formArray[indexEmail].value;
                        console.log(email);
                        if (res.link) {
                            authLink = res.link;
                        } else if (res && res.authtoken && email && res.hash && res.order_id) {
                            authLink = "https://studservis-lk.ru/auth/token=" + res.authtoken + "/email=" + email + "/hash=" + res.hash + "/order_id_info=" + res.order_id;
                        }
                        if (res.user_id) {
                            user_id = res.user_id;
                        }

                        firstForm.hide();
                        secondFrom.addClass('show');
                        activateBtn($('.custom-flex-container__button'), 'Продолжить');
                    },
                    error: function (error) {
                        activateBtn($('.custom-flex-container__button'), 'Продолжить');
                        alert('Ошибка отправки.');
                    }
                });

            } else {
                if (selectTypeOfWork.val() === '0') {
                    selectTypeOfWork.closest('.selectric-wrapper').addClass('error');
                }
                if (!inputEmailValidation) {
                    inputEmail.addClass('error');
                }
                console.log('unvalid');
            }
        })

        $('#biggest-form-second').submit(function (e) {
            console.log(e);
            e.preventDefault();
        }).validate({
            rules: {
                name: {
                    required: true,
                },
                phone: {
                    required: true,
                },
            },
            submitHandler: function (form) {
                const target = $(form);
                deactivateBtn($('.custom-flex-container__button-submited'));
                const formArray = target.serializeArray();
                const formData = new FormData();

                for (let elem of formArray) {
                    formData.append(elem.name, elem.value);
                }
                if (user_id) {
                    formData.append('user_id', user_id);
                }


                $.ajax({
                    type: 'POST',
                    url: '/wp-json/crm/v1/updateUser/',
                    data: formData,
                    dataType: "json",
                    processData: false,
                    contentType: false,
                    success: function (res) {
                        ym(YA_CLID, 'reachGoal', 'ORDER')
                        if (authLink) {
                            return (window.location.href = authLink);
                        } else {
                            window.location.href = 'https://studservis-lk.ru/orders/edit/';
                        }
                    },
                    error: function () {
                        activateBtn($('.custom-flex-container__button-submited'), 'Узнать цену');
                        alert('Ошибка отправки.');
                    }
                });
            }
        })
    } else {
        if (!!themeInput.length) {
            btn.on('click', function (e) {
                e.preventDefault();
                const valid = validateEmail($(emailInput).val());
                const btnText = $(this).attr('text');

                if (themeInput.val().length && emailBox.hasClass('top-panel__form-hidden')) {
                    $('.top-panel-bg').addClass('active');
                    emailBox.removeClass('top-panel__form-hidden');
                    addLabelText.addClass('custom-flex-container__add-text--right');
                    btn.text('Узнать цену');

                    return false;
                }

                if (!themeInput.val().length) {
                    themeInput.addClass('error');
                    themeInput.parent().find('.error-text').removeClass('hidden');

                    return false;
                }

                if (!valid) {
                    emailInput.addClass('error');
                    emailInput.parent().find('.error-text').removeClass('hidden');
                    return false;
                }
                if (valid) {
                    deactivateBtn($(this))
                    var dataForm = $(this).parents('form:first').serializeArray();
                    var dataObj = {};
                    if (getCookie('label_id')) {
                        dataForm.push({ name: 'label_id', value: getCookie('label_id') });
                    }
                    $(dataForm).each(function (i, field) {
                        dataObj[field.name] = field.value;
                    });
                    $.ajax({
                        type: 'POST',
                        url: '/wp-json/crm/v1/createUser/',
                        data: $.param(dataForm),
                        success: function (res) {
                            var url;
                            ym(YA_CLID, 'reachGoal', 'ORDER');
                            if (res.authlink) {
                                url = new URL(res.authlink + '/firstOrder/');
                            } else {
                                url = new URL('https://studservis-lk.ru/orders/edit/');
                            }
                            res.selected_work ?
                                url.searchParams.set('tpw', res.selected_work) : null;
                            res.theme ?
                                url.searchParams.set('theme', res.theme) : null;
                            QrCreator.render({
                                text: "https://t.me/testMattStudbot?start=" + $('#top-panel-form-email').val().replace('@', '__').replace('.', '_'),
                                radius: 0.5, // 0.0 to 0.5
                                ecLevel: 'H', // L, M, Q, H
                                fill: '#000000', // foreground color
                                background: null, // color or null for transparent
                                size: 250 // in pixels
                            }, document.querySelector('#qr-code'));
                            openPopup('qr-code-popup');
                            document.querySelector('#qr-code-popup .qr-code-popouop__info-lk-link').href = url.toString();
                            document.querySelector('#qr-code-popup .qr-code-popouop__info-tg-link').href = "https://t.me/testMattStudbot?start=" + $('#top-panel-form-email').val().replace('@', '__').replace('.', '_');
                            /*  window.location.href = url.toString(); */
                        },
                        error: function () {
                            activateBtn($(this), btnText)
                            alert('Ошибка отправки.');
                        }
                    });
                }
            });

            themeInput.on('keyup', function () {
                if (themeInput.hasClass('error')) {
                    themeInput.removeClass('error');
                    themeInput.parent().find('.error-text').addClass('hidden');
                }
            });

            emailInput.on('keyup', function () {
                if (emailInput.hasClass('error')) {
                    emailInput.removeClass('error');
                    emailInput.parent().find('.error-text').addClass('hidden');
                }
            });
        }

        if (!themeInput.length) {
            emailInput.on('keyup', function () {
                const valid = validateEmail($(this).val());

                if (emailInput.hasClass('error')) {
                    emailInput.removeClass('error');
                    emailInput.parent().find('.error-text').addClass('hidden');
                }

                if (!!valid) $('.top-panel-bg').addClass('active');
            });

            btn.on('click', function (e) {
                e.preventDefault();
                const valid = validateEmail($(emailInput).val());
                const btnText = $(this).attr('text');

                if (!valid) {
                    emailInput.addClass('error');
                    emailInput.parent().find('.error-text').removeClass('hidden');
                    return false;
                }
                if (valid) {
                    deactivateBtn($(this))
                    $.ajax({
                        type: 'POST',
                        url: '/wp-json/crm/v1/createUser/',
                        data: $(this).parents('form:first').serialize(),
                        success: function (res) {
                            var url;
                            ym(YA_CLID, 'reachGoal', 'ORDER')
                            /* selectedWork = (typeof res.selected_work !== "undefined" && res.selected_work) ? '?tpw=' + res.selected_work : '';
                            if (typeof res.authlink !== "undefined") {
                                window.location.href = res.authlink + '/firstOrder/' + selectedWork;
                            } else {
                                window.location.href = 'https://studservis-lk.ru/orders/edit/' + selectedWork;
                            } */
                            if (res.authlink) {
                                url = new URL(res.authlink + '/firstOrder/');
                            } else {
                                url = new URL('https://studservis-lk.ru/orders/edit/');
                            }
                            res.selected_work ?
                                url.searchParams.set('tpw', res.selected_work) : null;
                            res.theme ?
                                url.searchParams.set('theme', res.theme) : null;
                            window.location.href = url.toString();
                        },
                        error: function () {
                            activateBtn($(this), btnText)
                            alert('Ошибка отправки.');
                        }
                    });
                }
            });
        }
    }

    $('select:not(.custom)').selectric({
        maxHeight: 200,
        onChange: function (element) {
            $(element).change();
            if ($(element).hasClass('pop_calc_select')) {
                $("#calc_sale > span, #calc_price > span").text('-');
                var job = $(element).find('option:selected').attr('data-job');
                $("#calc_step").attr('disabled', false);
                console.log(job)
                if (typeof job !== "undefined") {
                    $.ajax({
                        type: 'POST',
                        url: '/wp-json/crm/v1/getJobInfo/',
                        data: {
                            tag: job
                        },
                        success: function (res) {
                            console.log(res);
                            console.log(typeof res.fields);
                            console.log((res.fields && typeof res.fields !== "undefined" && res.fields.tpw_price && typeof res.fields.tpw_price !== "undefined"));
                            if (typeof res.fields !== "undefined" && res.fields.tpw_price) {
                                var sale = (parseInt(res.fields.tpw_price) * 0.3);
                                sale = Math.round(sale / 100) * 100;
                                $("#calc_price > span").text(parseInt(res.fields.tpw_price));
                                $("#calc_sale > span").text(parseInt(sale));
                            }
                        },
                        error: function (xhr, status, error) {
                            var err = JSON.parse(xhr.responseText);
                            console.log(err);
                        }
                    });

                }

            }
        },
    });
});